<template>
  <!-- udesk就医偏好 -->
  <div class="medical-preference">
    <div v-if="list && list.length > 0" class="title">
      根据来电号码{{ phone }}，在ECCS中查得以下信息
    </div>
    <div class="title" v-else>
      根据此来电号码{{ phone }}，无法在ECCS中查到客户信息
    </div>

    <a-form :model="form" layout="inline" class="form">
      <a-form-item label="标签时效">
        <a-range-picker
          v-model="form.labelDate"
          :placeholder="['开始时间', '结束时间']"
          valueFormat="YYYY-MM-DD"
        ></a-range-picker>
      </a-form-item>
      <a-form-item>
        <a-radio-group v-model="form.dateScope" @change="handleChange">
          <a-radio value="1">近90天</a-radio>
          <a-radio value="2">近一年</a-radio>
          <a-radio value="3">近三年</a-radio>
          <a-radio value="4">全部</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item>
        <a-button @click="onSearch" type="primary">搜索</a-button>
      </a-form-item>
    </a-form>

    <div class="box">
      <div class="box-l">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="card"
          :class="{ active: item.cardNo === currentCardNo }"
          @click="selectCard(item.cardNo)"
        >
          {{ item.name || '--' }} ({{ item.cardNo || '--' }})
        </div>
      </div>
      <div class="box-r">
        <a-collapse :bordered="false" :defaultActiveKey="collapseKeys">
          <a-collapse-panel
            v-for="(tag, index) in tagList"
            :key="String(index)"
            :header="tag.pName"
            style="background: #fff"
          >
            <a-tag color="green" v-for="(d, i) in tag.tagList" :key="i">{{
              d
            }}</a-tag>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  data() {
    return {
      list: [],
      tagList: [],
      phone: '',
      currentCardNo: '',
      form: {
        dateScope: '4',
        labelDate: []
      },
      collapseKeys: ['0', '1', '2', '3', '4', '5', '6'] // 默认展开
    }
  },
  created() {
    const { phone } = this.$route.query
    this.phone = phone // 13501184117
    this.fetchData(phone || '')
  },
  methods: {
    fetchData(phone) {
      this.$apis
        .getEmployeesByPhone(phone)
        .then(res => res.data)
        .then(data => {
          this.list = data
          if (this.list.length) {
            this.currentCardNo = this.list[0].cardNo
            this.getTagList()
          }
        })
    },

    getTagList() {
      if (!this.currentCardNo) {
        return
      }
      const reqData = {
        dateStart: '',
        dateEnd: '',
        cardNo: this.currentCardNo
      }

      if (this.form.labelDate.length) {
        reqData.dateStart = dayjs(this.form.labelDate[0]).format(
          'YYYY-MM-DD 00:00:00'
        )
        reqData.dateEnd = dayjs(this.form.labelDate[1]).format(
          'YYYY-MM-DD 23:59:59'
        )
      }
      this.$apis
        .getUdeskTagList(reqData)
        .then(res => res.data)
        .then(data => {
          this.tagList = data.content
          this.collapseKeys = []
          this.tagList.forEach((d, i) => {
            this.collapseKeys.push(String(i))
          })
        })
    },

    selectCard(No) {
      this.currentCardNo = No
      this.getTagList()
    },

    handleChange() {
      this.form.labelDate = []
      const end = dayjs().format('YYYY-MM-DD')
      switch (this.form.dateScope) {
        case '1':
          this.form.labelDate.push(
            dayjs()
              .subtract(90, 'day')
              .format('YYYY-MM-DD')
          )
          this.form.labelDate.push(end)
          break
        case '2':
          this.form.labelDate.push(
            dayjs()
              .subtract(1, 'year')
              .format('YYYY-MM-DD')
          )
          this.form.labelDate.push(end)
          break
        case '3':
          this.form.labelDate.push(
            dayjs()
              .subtract(3, 'year')
              .format('YYYY-MM-DD')
          )
          this.form.labelDate.push(end)
          break
      }
    },

    onSearch() {
      this.getTagList()
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-preference {
  padding: 20px;
  .title {
    line-height: 1.6;
  }
  .form {
    margin-top: 14px;
  }
  .box {
    display: flex;
    border: 1px solid #666;
    height: 500px;
    margin-top: 12px;
    .box-l {
      width: 240px;
      overflow-y: auto;
      .card {
        text-align: center;
        padding: 12px 0;
        border-bottom: 1px solid #666;
        &.active {
          background: #00ac51;
          color: #fff;
        }
      }
    }
    .box-r {
      flex: 1;
      padding-left: 14px;
      overflow-y: auto;
      border-left: 1px solid #666;
    }
  }
}
</style>
