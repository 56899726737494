<template>
  <!-- udesk客户手机号识别 -->
  <div>
    <a-card
      v-if="list && list.length > 0"
      :title="`根据来电号码${phone}，在ECCS中查得以下信息，可点击更新到udesk中`"
    >
      <a-row :gutter="24">
        <a-col
          class="col_"
          v-for="(item, index) in list"
          :span="8"
          :key="index"
        >
          <a-card :title="item.source">
            <a slot="extra">
              <a-popconfirm
                @confirm="handleConfirm(item)"
                title="是否选中此信息添加到udesk"
              >
                <a-icon type="plus"></a-icon>
              </a-popconfirm>
            </a>
            <ul>
              <li class="text_underline">
                <a :href="getUrl(item)" target="_blank"
                  >Card：{{ item.cardNo || '--' }}</a
                >
              </li>
              <li>Name：{{ item.name || '--' }}</li>
              <li>Sex：{{ item.sex || '--' }}</li>
              <li>Date of Birth：{{ item.birthDay || '--' }}</li>
              <li>
                保险有效期：{{ item.startDate || '--' }} -
                {{ item.endDate || '--' }}
              </li>
              <li>Poyor Name：{{ item.payorName || '--' }}</li>
              <li>Corporate：{{ item.corporate || '--' }}</li>
              <li>Member Class：{{ item.mamberClass || '--' }}</li>
              <li>Member Status：{{ item.memberStatus || '--' }}</li>
            </ul>
          </a-card>
        </a-col>
      </a-row>
    </a-card>
    <div v-else>根据此来电号码{{ phone }}，无法在ECCS中查到客户信息</div>
  </div>
</template>
<style lang="scss" scoped>
.col_ {
  margin-bottom: 12px;
}
</style>
<script>
export default {
  data() {
    return {
      list: [],
      phone: ''
    }
  },
  created() {
    const { phone } = this.$route.query
    ;(this.phone = phone), //13501184117
      this.fetchData(phone || '')
  },
  methods: {
    // 跳转eccs的member/detail
    getUrl(item) {
      let url = ''
      let params = JSON.stringify({
        recordNoOnly: item.memberControlNo
      })
      let queryData = btoa(encodeURIComponent(params))
      if (process.env.NODE_ENV === 'production') {
        url = `https://nsecure.medilink-global.com.cn/eccsweb/member/detail?queryData=${queryData}`
      } else {
        url = `http://47.96.145.227/eccsweb/member/detail?queryData=${queryData}`
      }
      return url
    },
    fetchData(phone) {
      this.$apis
        .getEmployeesByPhone(phone)
        .then(res => res.data)
        .then(data => {
          this.list = data
        })
    },
    handleConfirm(item) {
      this.$apis
        .updateCustomer(item)
        .then(res => res.data)
        .then(() => {
          this.$message.success('add successfully.')
        })
    }
  }
}
</script>
<style scoped lang="scss">
.text_underline {
  text-decoration: underline;
  color: #00ac51;
  font-weight: bold;
}
</style>
